import { Component, Emit, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import moment from 'moment'
import { ServiceIntervalsService } from '@/App/Services/ServiceIntervalsService'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ServiceIntervalItem } from '@/App/Services/interfaces/service-interval.interface'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import SensorSelect from '@/App/Components/SensorSelect/Sensor-Select.vue'
import DatePicker from '@/App/Components/Pickers/DatePicker/Date-Picker.vue'

const sharedStore = namespace('Shared')

@Component({
    components: { DeviceSelect, SensorSelect, DatePicker }
})
export default class MakeServiceDialog extends Mixins(Validator) {   
    @sharedStore.Mutation
    private setNotification: (notification: Notification) => void

    public isOpen = false
    public isLoading = false
    public makeService: MakeService = {
        hours: 0,
        mileage: 0,
        date: moment().format('YYYY-MM-DD'),
        showHours: false,
        showMileage: false
    }

    public form = false

    public serviceIntervalId: string | null = null

    public async open(): Promise<void> {
        this.isOpen = true
    }

    public async edit(item: ServiceIntervalItem): Promise<void> {
        this.serviceIntervalId = item._id

        delete item._id

        this.makeService = {
            hours: 0,
            mileage: 0,
            date: moment().format('YYYY-MM-DD'),
            showHours: false,
            showMileage: false
        }

        if (item.intervals && item.intervals.hours) {
            this.makeService.showHours = true
            this.makeService.hours = item.intervals.hours.lastValue
        }

        if (item.intervals && item.intervals.mileage) {
            this.makeService.showMileage = true
            this.makeService.mileage = item.intervals.mileage.lastValue
        }

        await new Promise(r => setTimeout(r, 500))

        this.isOpen = true
    }

    public close(): void {
        this.isOpen = false
        this.makeService = {
            hours: 0,
            mileage: 0,
            date: moment().format('YYYY-MM-DD'),
            showHours: false,
            showMileage: false
        }

        this.serviceIntervalId = null
    }
    public submit(): void {
        this.save()
        this.isOpen = true
    }

    public async save(): Promise<void> {
        try {
            this.isLoading = true

            if (this.serviceIntervalId) {
                this.onServiceIntervalUpdate(await ServiceIntervalsService.makeService(
                    this.serviceIntervalId,
                    {
                        date: moment.utc(this.makeService.date).valueOf() / 1000,
                        hours: this.makeService.hours,
                        mileage: this.makeService.mileage
                    }
                ))
            }

            this.setNotification({ text: this.$t('serviceIntervalsPage.makeServiceDialog.dashboardCreatedNotificationText').toString() })

            this.close()
        } catch { } finally {
            this.isLoading = false
        }
    }

    @Emit('onServiceIntervalUpdate')
    onServiceIntervalUpdate(res: ServiceIntervalItem): ServiceIntervalItem {
        return res
    }

    get isSubmitFormDisabled(): boolean {
        return this.isLoading || !this.makeService.date
    }
}

interface MakeService {
    hours?: number
    mileage?: number
    date: string,
    showHours: boolean,
    showMileage: boolean
}
































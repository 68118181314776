import { Component, Emit, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import moment from 'moment'
import { ServiceIntervalsService } from '@/App/Services/ServiceIntervalsService'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ServiceIntervalItem, ServiceIntervalRequest } from '@/App/Services/interfaces/service-interval.interface'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import SensorSelect from '@/App/Components/SensorSelect/Sensor-Select.vue'
import DatePicker from '@/App/Components/Pickers/DatePicker/Date-Picker.vue'

const sharedStore = namespace('Shared')

@Component({
    components: { DeviceSelect, SensorSelect, DatePicker }
})
export default class ServiceIntervalsDialog extends Mixins(Validator) {
    @sharedStore.Mutation
    private setNotification: (notification: Notification) => void

    public isOpen = false
    public isLoading = false
    public serviceInterval: ServiceInterval = {
        name: '',
        deviceId: 0,
        emails: []
    }
    public form = false

    public serviceIntervalId: string | null = null

    get menuItems(): MenuItem[] {
        return [
            {
              text: this.$t('serviceIntervalsPage.serviceIntervalsDialog.addMileageInterval').toString(),
              show: () => !this.serviceInterval.intervals?.mileage,
              action: () => this.onAddMileageInterval()
            },
            {
              text: this.$t('serviceIntervalsPage.serviceIntervalsDialog.addHoursInterval').toString(),
              show: () => !this.serviceInterval.intervals?.hours,
              action: () => this.onAddHoursInterval()
            },
            {
              text: this.$t('serviceIntervalsPage.serviceIntervalsDialog.addDaysInterval').toString(),
              show: () => !this.serviceInterval.intervals?.days,
              action: () => this.onAddDaysInterval()
            }
          ]
    }

    public async open(): Promise<void> {
        this.isOpen = true
    }

    public close(): void {
        this.isOpen = false
        this.serviceInterval = {
            name: '',
            deviceId: 0,
            emails: []
        }

        if (this.serviceInterval.intervals) {
            delete this.serviceInterval.intervals
        }

        this.$forceUpdate()

        this.serviceIntervalId = null
    }
    public submit(): void {
        this.serviceIntervalId ? this.update() : this.save()
        this.isOpen = true
    }

    public async edit(item: ServiceIntervalItem): Promise<void> {
        this.serviceIntervalId = item._id

        delete item._id

        this.serviceInterval = {
            name: item.name,
            deviceId: item.deviceId,
            emails: item.emails
        }

        if (item.intervals) {
            this.serviceInterval.intervals = {}

            if (item.intervals.mileage) {
                this.serviceInterval.intervals.mileage = {
                    currentValue: item.intervals.mileage.currentValue,
                    intervalValue: item.intervals.mileage.intervalValue,
                    lastValue: item.intervals.mileage.lastValue,
                    sensorName: item.intervals.mileage.sensorName,
                    beforeValue: item.intervals.mileage.beforeValue
                }
            }

            if (item.intervals.hours) {
                this.serviceInterval.intervals.hours = {
                    currentValue: item.intervals.hours.currentValue,
                    intervalValue: item.intervals.hours.intervalValue,
                    lastValue: item.intervals.hours.lastValue,
                    sensorName: item.intervals.hours.sensorName,
                    beforeValue: item.intervals.hours.beforeValue
                }
            }

            if (item.intervals.days) {
                this.serviceInterval.intervals.days = {
                    lastValue: item.intervals.days.lastValue ? moment.unix(item.intervals.days.lastValue).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                    intervalValue: item.intervals.days.intervalValue,
                    beforeValue: item.intervals.days.beforeValue
                }
            }
        }

        await new Promise(r => setTimeout(r, 500))

        this.isOpen = true
    }

    public async save(): Promise<void> {
        try {
            this.isLoading = true

            this.onServiceIntervalUpdate(await ServiceIntervalsService.store(this.convertServiceinterval()))

            this.setNotification({ text: this.$t('serviceIntervalsPage.serviceIntervalsDialog.dashboardCreatedNotificationText').toString() })

            this.close()
        } catch { } finally {
            this.isLoading = false
        }
    }

    public async update(): Promise<void> {
        try {
            if (!this.serviceIntervalId) {
                return
            }

            this.isLoading = true

            this.onServiceIntervalUpdate(await ServiceIntervalsService.update(this.serviceIntervalId, this.convertServiceinterval()))

            this.setNotification({ text: this.$t('serviceIntervalsPage.serviceIntervalsDialog.dashboardUpdatedNotificationText').toString() })

            this.close()
        } catch { } finally {
            this.isLoading = false
        }
    }

    private convertServiceinterval() : ServiceIntervalRequest {
        const serviceInterval : ServiceIntervalRequest = {
            name: this.serviceInterval.name,
            deviceId: this.serviceInterval.deviceId,
            emails: this.serviceInterval.emails
        }

        if (this.serviceInterval.intervals) {
            serviceInterval.intervals = {}

            if (this.serviceInterval.intervals.mileage) {
                serviceInterval.intervals.mileage = {
                    currentValue: this.serviceInterval.intervals.mileage.currentValue,
                    intervalValue: this.serviceInterval.intervals.mileage.intervalValue,
                    lastValue: this.serviceInterval.intervals.mileage.lastValue,
                    sensorName: this.serviceInterval.intervals.mileage.sensorName,
                    beforeValue: this.serviceInterval.intervals.mileage.beforeValue
                }
            }

            if (this.serviceInterval.intervals.hours) {
                serviceInterval.intervals.hours = {
                    currentValue: this.serviceInterval.intervals.hours.currentValue,
                    intervalValue: this.serviceInterval.intervals.hours.intervalValue,
                    lastValue: this.serviceInterval.intervals.hours.lastValue,
                    sensorName: this.serviceInterval.intervals.hours.sensorName,
                    beforeValue: this.serviceInterval.intervals.hours.beforeValue
                }
            }

            if (this.serviceInterval.intervals.days) {
                serviceInterval.intervals.days = {
                    lastValue: this.serviceInterval.intervals.days.lastValue ?
                                moment.utc(this.serviceInterval.intervals.days.lastValue).valueOf() / 1000
                                : moment().utc().startOf('day').valueOf() / 1000,
                    intervalValue: this.serviceInterval.intervals.days.intervalValue,
                    beforeValue: this.serviceInterval.intervals.days.beforeValue
                }
            }
        }

        return serviceInterval
    }

    @Emit('onServiceIntervalUpdate')
    onServiceIntervalUpdate(res: ServiceIntervalItem): ServiceIntervalItem {
        return res
    }

    onAddMileageInterval() {
        if (!this.serviceInterval.intervals) {
            this.serviceInterval.intervals = {}
        }

        this.serviceInterval.intervals.mileage = {
            currentValue: 0,
            intervalValue: 0,
            lastValue: 0,
            sensorName: '',
            beforeValue: 0
        }

        this.$forceUpdate()
    }

    onAddHoursInterval() {
        if (!this.serviceInterval.intervals) {
            this.serviceInterval.intervals = {}
        }

        this.serviceInterval.intervals.hours = {
            currentValue: 0,
            intervalValue: 0,
            lastValue: 0,
            sensorName: '',
            beforeValue: 0
        }

        this.$forceUpdate()
    }

    onAddDaysInterval() {
        if (!this.serviceInterval.intervals) {
            this.serviceInterval.intervals = {}
        }

        this.serviceInterval.intervals.days = {
            intervalValue: 0,
            lastValue: '',
            beforeValue: 0
        }

        this.$forceUpdate()
    }

    public showAddMenu(): boolean {
        return !this.serviceInterval.intervals?.mileage
            || !this.serviceInterval.intervals?.hours
            || !this.serviceInterval.intervals?.days
    }

    public async deleteMileage() {
        delete this.serviceInterval.intervals?.mileage
        this.$forceUpdate()
    }

    public async deleteDays() {
        delete this.serviceInterval.intervals?.days
        this.$forceUpdate()
    }

    public async deleteHours() {
        delete this.serviceInterval.intervals?.hours
        this.$forceUpdate()
    }

    get dialogTitle(): string {
        return this.serviceIntervalId
            ? this.$t('serviceIntervalsPage.serviceIntervalsDialog.editText').toString()
            : this.$t('serviceIntervalsPage.serviceIntervalsDialog.titleText').toString()
    }

    get isSubmitFormDisabled(): boolean {
        return this.isLoading || !this.serviceInterval.name
    }

    get submitBtnText(): string {
        return this.serviceIntervalId
            ? this.$t('serviceIntervalsPage.serviceIntervalsDialog.updateText').toString()
            : this.$t('serviceIntervalsPage.serviceIntervalsDialog.saveText').toString()
    }

    get leftMileageValue(): number {
        if (!this.serviceInterval || !this.serviceInterval.intervals || !this.serviceInterval.intervals.mileage || ! this.serviceInterval.intervals.mileage.currentValue) {
            return 0
        }

        const leftMileageValue = parseInt(this.serviceInterval.intervals.mileage.intervalValue.toString())
          + parseInt(this.serviceInterval.intervals.mileage.lastValue.toString())
          - parseInt(this.serviceInterval.intervals.mileage.currentValue.toString())

        return leftMileageValue > 0 ? leftMileageValue : 0
    }

    get leftHoursValue(): number {
        if (!this.serviceInterval || !this.serviceInterval.intervals || !this.serviceInterval.intervals.hours) {
            return 0
        }

        const leftHoursValue = parseInt(this.serviceInterval.intervals.hours.intervalValue.toString())
        + parseInt(this.serviceInterval.intervals.hours.lastValue.toString())
        - parseInt(this.serviceInterval.intervals.hours.currentValue.toString())

        return leftHoursValue > 0 ? leftHoursValue : 0
    }

    get leftDaysValue(): number {
        if (!this.serviceInterval || !this.serviceInterval.intervals || !this.serviceInterval.intervals.days) {
            return 0
        }
        const nowDateTime = moment.utc().valueOf()
        const lastDaysServiceDateTime = moment.utc(moment.utc(this.serviceInterval.intervals.days.lastValue).toString()).valueOf()
        const leftDaysValue = Math.ceil((lastDaysServiceDateTime - nowDateTime) / 86400000)
        + parseInt(this.serviceInterval.intervals.days.intervalValue.toString())

        return leftDaysValue > 0 ? leftDaysValue : 0
    }
}
export interface ServiceInterval {
    name: string
    deviceId: number
    emails: string[]
    intervals?: {
        mileage?: {
            sensorName: string
            intervalValue: number
            currentValue: number
            lastValue: number
            beforeValue: number
        },
        hours?: {
            sensorName: string
            intervalValue: number
            currentValue: number
            lastValue: number
            beforeValue: number
        },
        days?: {
            lastValue: string
            intervalValue: number
            beforeValue: number
        }
    }
}

interface MenuItem {
    text: string;
    show: () => boolean;
    action: () => void;
}
































import { HTTP } from '@/App/Utils/axios'
import { MakeServiceRequest, ServiceIntervalItem, 
    ServiceIntervalRequest
} from './interfaces/service-interval.interface'

export class ServiceIntervalsService {
    static async store(serviceInterval: ServiceIntervalRequest ): Promise<ServiceIntervalItem> {
        try {
            const { data } = await HTTP.post( 'service-intervals', serviceInterval)

            return data
        } catch (e) {
            throw e
        }
    }

    static async fetch(): Promise<ServiceIntervalItem[]> {
        try {
            const { data } = await HTTP.get('service-intervals')

            return data
        } catch (e) {
            throw e
        }
    }

    static async update(id: string, serviceInterval: ServiceIntervalRequest): Promise<ServiceIntervalItem> {
        try {
            const { data } = await HTTP.patch(`/service-intervals/${id}`, serviceInterval)

            return data
        } catch (e) {
            throw e
        }
    }

    static async destroy(id: string): Promise<void> {
        try {
             await HTTP.delete(`/service-intervals/${id}`)
        } catch (e) {
            throw e
        }
    }

    static async makeService(id: string, makeService: MakeServiceRequest): Promise<ServiceIntervalItem> {
        try {
            const { data } = await HTTP.post(`/service-intervals/make-service/${id}`, makeService)

            return data
        } catch (e) {
            throw e
        }
    }
}

import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ServiceIntervalsService } from '@/App/Services/ServiceIntervalsService'
import TableHeader from '@/App/Components/TableHeader/Table-Header.vue'
import ServiceIntervalsDialog from '../ServiceIntervalsDialog/Service-Intervals-Dialog.vue'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'
import { TABLE_HEADERS, TABLE_HEADER_BTN } from '@/App/Pages/ServiceIntervals/constants'
import { ServiceIntervalItem } from '@/App/Services/interfaces/service-interval.interface'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { FlespiService } from '@/App/Services/Flespi.service'
import MakeServiceDialog from '../MakeServiceDialog/Make-Service-Dialog.vue'

const sharedStore = namespace('Shared')

@Component({
    components: { ServiceIntervalsDialog, TableHeader, MakeServiceDialog }
})
export default class ServiceIntervalsTable extends Vue {
    $refs: {
        serviceIntervalsDialog: HTMLFormElement,
        makeServicesDialog: HTMLFormElement
    }

    @sharedStore.Mutation
    private readonly setNotification: (notification: Notification) => void

    @sharedStore.Action
    private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

    public search = ''
    public item: ServiceIntervalItem[] = []
    public isLoading = false
    public headers: VuetifyTableHeader[] = []
    public headerActionsBtn: TableHeaderBtn[] = []
    public deviceItems: { id: number, name: string }[] = []

    public onEditServiceInterval(item: string): void {
        this.$refs.serviceIntervalsDialog.edit(item)
    }
    public onMakeService(item: string): void {
        this.$refs.makeServicesDialog.edit(item)
    }

    public onSearch(text: string): void {
        this.search = text
    }

    public onServiceIntervalUpdate(serviceInterval: ServiceIntervalItem): void {
        const candidate = this.item.find(item => item._id === serviceInterval._id)
        if (candidate) {
            this.item = this.item.map(item => {
                if (item._id === candidate._id) {
                    return serviceInterval
                } else {
                    return item
                }
            })
        } else {
            this.item.push(serviceInterval)
        }
    }

    public async onDelete(id: string) {
        const title = this.$t('serviceIntervalsPage.serviceIntervalsTable.confirmTitleText1').toString()
        const text = `
        ${this.$t('serviceIntervalsPage.serviceIntervalsTable.confirmTitleText2')}<br/>
        ${this.$t('serviceIntervalsPage.serviceIntervalsTable.confirmTitleText3')}<br/>
        ${this.$t('serviceIntervalsPage.serviceIntervalsTable.confirmTitleText4')}
    `
        if (await this.confirm({ title, text })) {
            try {
                await ServiceIntervalsService.destroy(id)

                this.item = this.item.filter(item => item._id !== id)

                this.setNotification({ text: this.$t('serviceIntervalsPage.serviceIntervalsTable.deletedNotificationText').toString() })
            } catch { }
        }
    }

    public async mounted(): Promise<void> {
        this.headers = TABLE_HEADERS
        this.headerActionsBtn = TABLE_HEADER_BTN
        try {
            this.isLoading = true

            this.item = await ServiceIntervalsService.fetch()

            const devices = await FlespiService.fetchDevices()

            this.deviceItems = devices.map(d => {
                return { id: d.id, name: d.name }
            })

        } catch { } finally {
            this.isLoading = false
        }
    }

    public getDeviceName(id: number): string {
        const device = this.deviceItems.find(x => x.id === id)
        return device?.name ?? ''
    }

    public isTriggered(item: ServiceIntervalItem): boolean {
        return item.intervals ? 
            (
                !!item.intervals.mileage?.isTriggered || 
                !!item.intervals.days?.isTriggered || 
                !!item.intervals.hours?.isTriggered
            )
            : false
    }
}


import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { FlespiService } from '@/App/Services/Flespi.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'

const sharedStore = namespace('Shared')

@Component
export default class SensorSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  @Prop({ required: true })
  private readonly deviceId!: string

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: false })
  public dark: boolean

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public sensorName = ''
  public sensorsItems: string[] = []
  public isLoading = false

  private async fillSensorsItems(): Promise<void> {
    try {
      if (this.deviceId) {
        this.isLoading = true

        this.sensorsItems = []

        this.sensorsItems = await FlespiService.fetchDeviceSensors(this.deviceId)
        this.sensorName = this.value
      }
    } catch {} finally {
      this.isLoading = false
    }
  }

  public validation(): ValidationResult {
    return this.required ? this.validator.isEmpty(this.sensorName) : true
  }

  public onSensorSelected(): void {
    this.$emit('input', this.sensorName)
  }

  @Watch('deviceId')
  private deviceIdChanged(): void {
    this.fillSensorsItems()
  }

  @Watch('value')
  private valueChanged(val: string): void {
    this.sensorName = val
  }

  private mounted(): void {
    this.fillSensorsItems()

    this.sensorName = this.value
  }
}

import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'
import i18n from '@/plugins/i18n'

const locale = localStorage.getItem('localization') || 'en'

export const TABLE_HEADERS: VuetifyTableHeader[] = [
    { text: i18n.t('serviceIntervalsPage.constants.nameText', locale).toString(), align: 'left', sortable: true, value: 'name' },
    { text: i18n.t('serviceIntervalsPage.constants.deviceText', locale).toString(), align: 'left', sortable: true, value: 'deviceId' },
    { text: i18n.t('serviceIntervalsPage.constants.actionText', locale).toString(), value: 'left', align: 'center', sortable: false }
]
export const TABLE_HEADER_BTN: TableHeaderBtn[] = [
    { icon: 'fa fa-plus', tooltip: i18n.t('serviceIntervalsPage.constants.tooltipText', locale).toString(), action: 'onAddServiceIntervals' }
]
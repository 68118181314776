import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TableHeader extends Vue {
  @Prop({ default: '' })
  public readonly title: string

  @Prop({ default: false })
  public readonly isSearchBtnShow: boolean

  @Prop({ default: false })
  public readonly showBackBtn: boolean

  @Prop({ default: [] })
  public readonly btnItems: TableHeaderBtn[]

  public isSearchActive = false

  public inSearchBtnClick() {
    this.isSearchActive = !this.isSearchActive
  }

  public onSearch(search: string): void {
    this.$emit('search', search)
  }
}

export interface TableHeaderBtn {
  icon: string
  tooltip: string
  action: string
}
